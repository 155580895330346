<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12" md="12">
            <b-card no-body>
              <b-card-header>
                <h4 class="card-title">{{i18nT(`Task group`)}}</h4>
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="onCopy()">
                    <span class="align-middle ml-50">{{i18nT(`Copy`)}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onSave()">
                    <span class="align-middle ml-50">{{i18nT(`Save`)}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="hasRouteId" @click="onDelete()">
                    <span class="align-middle ml-50" style="color: red">{{i18nT(`Delete`)}}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-card-header>
              <b-card-body>
                <b-row class="border-bottom">
                  <!-- Field: Username -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <h5>
                      {{ i18nT(`Group information`) }}
                    </h5>
                    <p class="mt-1">{{ i18nT(`Create a group of tasks to easily assign them to a person later.`) }}</p>
                  </b-col>
                  <b-col
                    cols="12"
                    md="9"
                  >
                    <b-row>
                      <b-col>
                        <b-form-group
                          :label="i18nT(`Title`)"
                          label-for="title"
                          class="required"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Title`)"
                            rules="required"
                          >
                            <b-form-input
                              id="title"
                              v-model="taskGroup.Label"
                              :state="errors.length > 0 ? false:null"
                            />                              
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>                    
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          :label="i18nT(`Description`)"
                          label-for="description"
                        >
                          <b-form-textarea
                            id="textarea-default"
                            rows="3"
                            v-model="taskGroup.Description"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="border-bottom pt-2 pb-2">
                  <!-- Field: Username -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <h5>
                      {{ i18nT(`Tasks`) }}
                    </h5>
                    
                  </b-col>
                  <b-col
                    cols="12"
                    md="9"
                  >
                    <h6 class="mb-1">{{i18nT(`Tasks list`)}}</h6>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      v-b-modal.modal-1
                      class="d-block mt-2"
                    >
                      <feather-icon icon="PlusCircleIcon" /> 
                      {{i18nT(`Add new`)}}
                    </b-button>
                    <div class="mt-1" v-if="selectedTask.length > 0">
                      <div class="mb-3 mt-2">
                        <h6 class="d-inline">{{i18nT(`Tasks list`)}}</h6>
                        <b-button
                          variant="outline-danger"
                          class="float-right"
                          @click="clearSelectedTask"
                        >
                          <feather-icon icon="MinusCircleIcon" />
                          {{ i18nT(`Clear all`) }}
                        </b-button>
                      </div>
                      <b-list-group>
                        <b-list-group-item v-for="task in selectedTask" :key="task.value">
                          <div>
                            <b-link
                                :to="{ name: 'task-view', params: { id: task.value }}"
                                target="_blank">
                            <span class="document-name">{{task.title}}</span>
                            </b-link>
                            <b-button-group
                              size="sm"
                              class="document-actions-bar"
                            >
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                :to="{ name: 'edit-tasks', params: { id: task.value, software: curSoftware }}"
                                target="_blank"
                              >
                                <feather-icon icon="Edit3Icon" size="16" style="margin: 0;"/>
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                @click="onTaskRemove(task)"
                              >
                                <feather-icon icon="Trash2Icon" size="16" style="margin: 0;"/>
                              </b-button>
                            </b-button-group>
                          </div>
                        </b-list-group-item>
                      </b-list-group>                  
                    </div> 
                  </b-col>
                </b-row>
                <b-row>
                    <!-- <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2 ml-1 mr-1"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{i18nT(`Delete`)}}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="mt-0 mt-md-2 ml-1 mr-1"
                    >
                      Preview
                    </b-button> -->
                    <div style="flex: 1" />
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-0 mt-md-2 ml-1 mr-1"
                      @click.prevent="validationForm"
                    >
                    {{ i18nT(`Save`) }}
                    </b-button>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-modal
      id="modal-1"
      :title="i18nT(`Add task`)"
      ok-only
      :ok-title="i18nT(`Save`)"    
      no-close-on-backdrop
      @ok="onTaskSelect"
    >
      <div>
        <!--<h5>
          {{i18nT(`Select one or more tasks.`)}}
        </h5>-->
        <b-form-group
          :label="i18nT(`Select task(s)`)"
          label-for="assignee"
        >
          <v-select
            v-model="selectedTaskTemp"
            multiple
            label="title"
            :options="taskOptions"
          />
        </b-form-group>
      </div>
    </b-modal>
  </div>
  
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BFormTextarea,
  BCardBody,
  BModal,
  VBModal,
  BButtonGroup,
  BListGroup,
  BListGroupItem,
  BCardHeader,
  BDropdown,
  BDropdownItem,
  BLink
} from 'bootstrap-vue'
import vSelect from 'vue-select'
//import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
//import { ref } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import router from '@/router'
import useAuth from "@/auth/useAuth";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardBody,
    vSelect,
    BFormTextarea,
    BModal,
    BButtonGroup,
    BListGroup,
    BListGroupItem,    
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    BDropdown, 
    BDropdownItem
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      curSoftware: useAuth.getCurrentSoftware(),
      taskOptions: [],
      tasks: [],
      selectedTaskTemp: [],
      selectedTask: [],
      taskGroup: {
        Description: '',
        Label: ''
      },
      required,
      email
    }
  },
  created() {    
    this.$http.get(
      `tasks?show_per_page=100`
    ).then(({data}) => {
      this.tasks = data.data.tasks
      this.taskOptions = data.data.tasks.map(task => ({
        value: task.Id,
        title: task.Label
      }))
    })

    if(router.currentRoute.params.id) {
      this.$http.get(
        `tasks/taskGroups?id=${router.currentRoute.params.id}`
      ).then(({data}) => {
        this.taskGroup = data.data.group
        this.selectedTaskTemp = this.selectedTask = data.data.tasks.map((task) => ({
          value: task.Id,
          title: task.Label
        }))
      })
    }
  },
  setup() {
    return  {
    }
  },
  computed: {
    hasRouteId() {
      return router && router.currentRoute.params.id
    }
  },
  methods: {
    onTaskSelect: function() {
      this.selectedTask = this.selectedTaskTemp
    },
    onTaskRemove: function(val) {
      this.selectedTask = this.selectedTask.filter((task) => task.value != val.value)
    },
    clearSelectedTask: function() {
      this.selectedTask = []
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          const formData = new FormData()
          formData.append('Label', this.taskGroup.Label)
          formData.append('Description', this.taskGroup.Description)
          formData.append('group_tasks', this.selectedTask.map(task => task.value).join(","))
          if(router.currentRoute.params.id) {
            formData.append('id', router.currentRoute.params.id)
          }
          this.$http.post(
            'tasks/taskGroups', formData
          ).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Task group ${router.currentRoute.params.id ? 'updated': 'created'}.`,
                icon: 'InfoIcon',
                variant: 'success',
              },
            })

            router.push({name: 'task-groups'})
          })
        }
      })
    },
    onSave() {
      console.log('Saved!!!')
    },
    onDelete() {
      this.$swal({
        title: 'Are you sure you want to delete this entry?',        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          console.log('Deleted!!!')
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .section-title {
    font-size: 11px;
  }
  .document-name {
    display: inline;
    line-height: 32px;
    color: black;
  }
  .document-actions-bar {
    float: right;
  }
</style>
